import React, { useEffect, useState } from "react";
import styles from "./monthlyReport.module.scss";
import { useDebounce } from "../custom hooks/Debounce";
import { handleGetRequest } from "../services/GetTemplate";

function MonthlyReport() {
    const [flightNumber, setFlightNumber] = useState();
    const [month, setMonth] = useState();
    const [year, setYear] = useState();
    const debouncedValue = useDebounce(flightNumber, 1000);
    const [report, setReport] = useState();
    const currentDate = new Date();

    useEffect(() => {
        const currentDate = new Date();
        setMonth(currentDate.getMonth() + 1); // Months are zero-based
        setYear(currentDate.getFullYear());
    }, []);

    const getData = async () => {
        const res = await handleGetRequest(`/invoice/monthlyReport?year=${year}&month=${month}`);
        console.log(res?.data);
        setReport(res?.data);
    };

    useEffect(() => {
        getData();
    }, [month, year]);

    const getMonthBasedOnNumber = (monthNumber) => {
        switch (monthNumber) {
            case "01":
                return "January";
            case "02":
                return "February";
            case "03":
                return "March";
            case "04":
                return "April";
            case "05":
                return "May";
            case "06":
                return "June";
            case "07":
                return "July";
            case "08":
                return "August";
            case "09":
                return "September";
            case "10":
                return "October";
            case "11":
                return "November";
            case "12":
                return "December";
            default:
                return "";
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.outer}>
                <div className={styles.inputDiv}>
                    <label>Month</label>
                    <select value={month} onChange={(e) => setMonth(e.target.value)}>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                </div>
                <div className={styles.inputDiv}>
                    <label>Year</label>
                    <select value={year} onChange={(e) => setYear(e.target.value)}>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                        <option value="2028">2028</option>
                        <option value="2029">2029</option>
                        <option value="2030">2030</option>
                    </select>
                </div>
            </div>

            <div className={styles.reportDiv}>
                {report?.flights?.length > 0 ? (
                    <div className={styles.reportInner}>
                        <p>
                            <span className={styles.bold}>Month:</span> {getMonthBasedOnNumber(month.toString())}
                        </p>
                        <div className={styles.mainReport}>
                            <p>
                                <span className={styles.bold}>Total Amount:</span> ${report?.totalCost}
                            </p>
                            <p>
                                <span className={styles.bold}>Total Fees:</span> ${report?.totalFees}
                            </p>
                            <p>
                                <span className={styles.bold}>Total price for pounds shipped:</span> ${report?.totalPoundsShippedPrice}
                            </p>
                            <p>
                                <span className={styles.bold}>Total Pending Amount:</span> ${report?.totalPendingCost}
                            </p>
                            <p>
                                <span className={styles.bold}>Total Paid Amount:</span> ${report?.totalPaidAmount}
                            </p>
                            <p>
                                <span className={styles.bold}>Total Pending Invoices:</span> ${report?.pendingInvoices}
                            </p>
                        </div>
                        {report?.flights.map((item, index) => {
                            return (
                                <div className={styles.pendingReport} key={index}>
                                    <div className={styles.topDiv}>
                                        <p>
                                            <span className={styles.bold}>Flight Number:</span> {item?.flightNumber}
                                        </p>
                                        <div>
                                            <p>
                                                <span className={styles.bold}>Pending:</span> ${item?.pendingCost}
                                            </p>
                                        </div>
                                    </div>
                                    <div className={styles.invoicesDiv}>
                                        <div key={index}>
                                            {/* <p>
                                                <span className={styles.bold}>Invoice Number:</span>
                                                <a href={`/invoice?id=${invoice?.id}`} target="_blank">
                                                    {invoice?.invoiceNumber}
                                                </a>{" "}
                                            </p> */}
                                            <div>
                                                <p>
                                                    <span className={styles.bold}>Total Amount:</span> ${item?.totalCost}
                                                </p>
                                                <p>
                                                    <span className={styles.bold}>Fees:</span> ${item?.totalFees}
                                                </p>
                                                <p>
                                                    <span className={styles.bold}>Price for pounds shipped:</span> ${item?.totalPoundsShippedPrice}
                                                </p>
                                                <p>
                                                    <span className={styles.bold}>Paid Amount:</span> ${item?.totalPaidAmount}
                                                </p>
                                                <div className={styles.invoicesInner}>
                                                    <p> Pending Invoices (Pending Amount)</p>
                                                    <div className={styles.invoicesInnerMost}>
                                                        {item?.invoices.map((invoice, index) => {
                                                            return(
                                                                <div>
                                                                    <a href={`/invoice?id=${invoice?.invoiceId}`} target="_blank">
                                                                        {invoice?.invoiceNumber}
                                                                    </a>
                                                                    <p>{invoice?.clientName}</p>
                                                                    <p>${invoice?.pendingAmount}</p>
                                                                </div>
                                                            )
                                                        })};
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <p>No pending invoice found</p>
                )}
            </div>
        </div>
    );
}

export default MonthlyReport;
