import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../../services/PostTemplate";
import moment from "moment/moment";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { handleGetRequest } from "../../../services/GetTemplate";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase/firebase";
import ClipLoader from "react-spinners/ClipLoader";
import { handlePutRequest } from "../../../services/PutTemplate";
import Select from "react-select";

function AddHandlerMilage({ onHideTyreBrandsDialog, onsuccess, dat }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [image, setImage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    console.log(dat);

    useEffect(() => {
        if (dat) {
            setSelectedUser({ label: dat?.user?.firstName + " " + dat?.user?.lastName, value: dat?.user?._id });
            setSelectedVehicle({ label: dat?.vehicle?.Number, value: dat?.vehicle?._id });
            setImage(dat?.images);
        }
    }, [dat]);

    const getData = async () => {
        const res = await handleGetRequest("/allUsers");
        const res2 = await handleGetRequest("/getVehicles");
        const filtered = res?.data.filter((item) => item?.role == "handler");
        const temp = filtered?.map((item) => {
            return { label: item?.firstName + " " + item?.lastName, value: item._id };
        });
        const temp2 = res2?.data.map((item) => {
            return { label: item?.Number, value: item._id };
        });
        setUsers(temp);
        setVehicles(temp2);
        const userId = localStorage.getItem("userId");
        const username=localStorage.getItem("user");
        setSelectedUser({
            label: username,
            value: userId,
        })
    };

    useEffect(() => {
        getData();
    }, []);

    console.log(selectedUser, selectedVehicle);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: dat?.id || "",
            username: dat?.username || "",
            vehicleNumber: dat?.vehicleNumber || "",
            user: dat?.user ? { label: dat?.user?.firstName + " " + dat?.user?.lastName, value: dat?.user?._id } : "",
            vehicle: dat?.vehicle ? { label: dat?.vehicle?.Number, value: dat?.vehicle?._id } : "",
            startMilage: dat?.startMilage || "",
            amount: dat?.amount || "",
            currency: dat?.currency || "USD",
            images: dat?.images || [],
        },

        onSubmit: async (data) => {
            data.images = image;
            data.username = selectedUser?.label;
            data.user = selectedUser?.value;
            data.vehicle = selectedVehicle?.value;
            data.vehicleNumber = selectedVehicle?.label;
            data.dateJoined = Date.now();

            if (!dat) {
                const result = await dispatch(handlePostRequest(data, "/addMilage", true, true));
                if (result !== "error") {
                    onsuccess();
                }
                setImage(null);
            } else {
                data.id = dat._id;
                const result = await handlePutRequest(data, "/updateMilage");
                if (result?.success) {
                    onsuccess();
                }
            }
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    const uploadImage = async (file) => {
        setLoading(true);
        let name = file.name;
        let filename = name.replace(/\.[^/.]+$/, "") + "-" + Math.floor(Math.random() * 10000000 + 1);
        const storageRef = ref(storage, filename);
        await uploadBytes(storageRef, file).then((snapshot) => {});
        await getDownloadURL(storageRef).then(async (url) => {
            console.log(url);
            setImage([...image, url]);
        });
        setLoading(false);
    };

    const handleDelete = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        const temp = image;
        temp.splice(index, 1);
        setImage([...temp]);
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="id" className={classNames({ "p-error": isFormFieldValid("id") }, "Label__Text")}>
                                Report Id
                            </label>
                            <InputText id="id" name="id" value={formik.values.id} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("id") }, "Input__Round")} />

                            {getFormErrorMessage("id")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="startMilage" className={classNames({ "p-error": isFormFieldValid("startMilage") }, "Label__Text")}>
                                Starting Milage
                            </label>
                            <InputText id="startMilage" name="startMilage" value={formik.values.startMilage} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("startMilage") }, "Input__Round")} />

                            {getFormErrorMessage("startMilage")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="amount" className={classNames({ "p-error": isFormFieldValid("amount") }, "Label__Text")}>
                                Fuel Amount
                            </label>
                            <InputText id="amount" name="amount" value={formik.values.amount} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("amount") }, "Input__Round")} />

                            {getFormErrorMessage("amount")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="currency" className={classNames({ "p-error": isFormFieldValid("currency") }, "Label__Text")}>
                                Currency
                            </label>
                            {/* <InputText id="currency" name="currency" value={formik.values.currency} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("currency") }, "Input__Round")} /> */}
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <select
                                    id="currency"
                                    name="currency"
                                    value={formik.values.currency}
                                    onChange={formik.handleChange}
                                    style={{
                                        marginTop: "10px",
                                        height: "40px",
                                        borderRadius: "5px",
                                        border: "1px solid #ccc",
                                    }}
                                >
                                    <option value="USD">USD</option>
                                    <option value="JMD">JMD</option>
                                </select>
                            </div>
                            {getFormErrorMessage("currency")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="amount" className={classNames({ "p-error": isFormFieldValid("amount") }, "Label__Text")}>
                                User
                            </label>
                            <Select options={users} value={selectedUser} onChange={setSelectedUser} isDisabled></Select>

                            {getFormErrorMessage("amount")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="amount" className={classNames({ "p-error": isFormFieldValid("amount") }, "Label__Text")}>
                                Vehicle
                            </label>
                            <Select options={vehicles} value={selectedVehicle} onChange={setSelectedVehicle}></Select>
                            {getFormErrorMessage("amount")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        {loading ? (
                            <ClipLoader color={"#2C4870"} loading={loading} size={30} />
                        ) : (
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ "p-error": isFormFieldValid("email") }, "Label__Text")}>
                                    Milage Images
                                </label>
                                <div style={{ marginTop: "10px" }}>
                                    <input type="file" onChange={(e) => uploadImage(e.target.files[0])}></input>
                                </div>

                                {getFormErrorMessage("email")}
                            </div>
                        )}
                    </div>
                    <div style={{ display: "flex", columnGap: "10px", padding: "10px" }}>
                        {image?.map((ima, index) => {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        border: "1px solid #ccc",
                                    }}
                                >
                                    <button onClick={(e) => handleDelete(e, index)}>Delete</button>
                                    <img src={ima} style={{ width: "80px", objectFit: "cover", marginTop: "20px", height: "80px" }}></img>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="Down__Btn">
                    <Button label={dat ? "Edit" : "Create"} className="Btn__Dark" />
                </div>
            </form>
        </>
    );
}

export default AddHandlerMilage;
