import React, { useEffect, useState } from "react";
import styles from "./flightReport.module.scss";
import { useDebounce } from "../custom hooks/Debounce";
import { handleGetRequest } from "../services/GetTemplate";

function FlightReport() {
    const [flightNumber, setFlightNumber] = useState();
    const debouncedValue = useDebounce(flightNumber, 1000);
    const [report, setReport] = useState();

    const getData = async () => {
        const res = await handleGetRequest(`/invoice/flightReport?flightNumber=${debouncedValue}`);
        console.log(res?.data);
        setReport(res?.data);
    };

    useEffect(() => {
        if(debouncedValue){
            getData();
        }
    }, [debouncedValue]);
    return (
        <div className={styles.container}>
            <div className={styles.inputDiv}>
                <label>Flight Number</label>
                <input type="text" value={flightNumber} onChange={(e) => setFlightNumber(e.target.value)} />
            </div>
            <div className={styles.reportDiv}>
                {report?.pendingDetails?.length > 0 ? (
                    <div className={styles.reportInner}>
                        <p>
                            <span className={styles.bold}>Flight Number:</span> {report?.flightNumber}
                        </p>
                        <div className={styles.mainReport}>
                            <p>
                                <span className={styles.bold}>Total Amount:</span> ${report?.totalCost}
                            </p>
                            <p>
                                <span className={styles.bold}>Total Fees:</span> ${report?.totalFees}
                            </p>
                            <p>
                                <span className={styles.bold}>Total price for pounds shipped:</span> ${report?.totalPoundsShippedPrice}
                            </p>
                            <p>
                                <span className={styles.bold}>Total Pending Amount:</span> ${report?.pendingCost}
                            </p>
                            <p>
                                <span className={styles.bold}>Total Pending Invoices:</span> ${report?.pendingInvoices}
                            </p>
                        </div>
                        {report?.pendingDetails.map((item, index) => {
                            return (
                                <div className={styles.pendingReport} key={index}>
                                    <div className={styles.topDiv}>
                                        <p>
                                            <span className={styles.bold}>Client Name:</span> {item?.clientName}
                                        </p>
                                        <div>
                                            <p>
                                                <span className={styles.bold}>Pending:</span> ${item?.totalPendingAmount}
                                            </p>
                                        </div>
                                    </div>
                                    <div className={styles.invoicesDiv}>
                                        {item?.invoices.map((invoice, index) => {
                                            return (
                                                <div key={index}>
                                                    <p>
                                                        <span className={styles.bold}>Invoice Number:</span>
                                                        <a href={`/invoice?id=${invoice?.id}`} target="_blank">
                                                            {invoice?.invoiceNumber}
                                                        </a>{" "}
                                                    </p>
                                                    <div>
                                                        <p>
                                                            <span className={styles.bold}>Waybills:</span> {invoice?.Waybills}
                                                        </p>
                                                        <p>
                                                            <span className={styles.bold}>Fees:</span> ${invoice?.fees}
                                                        </p>
                                                        <p>
                                                            <span className={styles.bold}>Price for pounds shipped:</span> ${invoice?.poundsShippedPrice}
                                                        </p>
                                                        <p>
                                                            <span className={styles.bold}>Pending Amount:</span> ${invoice?.totalPendingAmount}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <p>No pending invoice found</p>
                )}
            </div>
        </div>
    );
}

export default FlightReport;
