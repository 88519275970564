import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { handleGetRequest } from "../../services/GetTemplate";
import "./invoice.scss";
import { Tooltip } from "react-tippy";
import { toast } from "react-toastify";
import Water from "../common/WaterMark";
import generatePDF, { Margin } from "react-to-pdf";
import { Dialog } from "primereact/dialog";
import PaymentModel from "./PaymentModel";

function Invoice() {
    const targetRef = useRef();
    const history = useHistory();
    const [brands, setBrands] = useState();
    const [packages, setPackages] = useState([]);
    const [oversize, setOversize] = useState(0);
    const [dangerous, setDangerous] = useState(0);
    const [custom, setCustom] = useState(0);
    const [total, setTotal] = useState(0);
    const [loyalty, setLoyalty] = useState(0);
    const [pickup, setPickup] = useState(0);
    const [packaging, setPackaging] = useState(0);
    const [declaration, setDeclaration] = useState(0);
    const [fullTotal, setFullTotal] = useState(0);
    const [paid, setPaid] = useState(false);
    const [show, setShow] = useState(false);
    const [notpaid, setNotPaid] = useState(false);
    const [notes, setNotes] = useState();
    const [SubTotal, setSubTotal] = useState(0);
    const [discountedAmount, setDiscountedAmount] = useState(0);
    const [monthly, setMonthly] = useState(0);
    const [website, setWebsite] = useState(0);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const getBrands = async () => {
        console.log(id);
        const res = await handleGetRequest("/specificInvoice/" + id);
        setBrands(res?.data);
        setPackages(res?.data?.packages);
        setOversize(res?.data?.oversizeFee);
        setDangerous(res?.data?.dangerousItemFee);
        setCustom(res?.data?.customDuties);
        setTotal(res?.data?.invoiceAmount);
        setFullTotal(res?.data?.invoiceAmount);
        setLoyalty(res?.data?.loyaltyDiscount);
        setPickup(res?.data?.pickupFee);
        setPackaging(res?.data?.packagingFee);
        setDeclaration(res?.data?.declarationFee);
        setNotes(res?.data?.notes);
        setSubTotal(res?.data?.TotalAmount);
        setDiscountedAmount(res?.data?.discountedAmount);
        setMonthly(res?.data?.MonthlyFee);
        setWebsite(res?.data?.WebsiteFee);
        if (res?.data?.paymentStatus === "paid") {
            setPaid(true);
            setShow(false);
        }
    };
    useMemo(() => {
        getBrands();
    }, [id]);
    const componentRef = useRef();

    console.log(brands);

    const handleMonthly = (e) => {
        setMonthly(parseInt(e));
        const total = parseInt(e) + website;
        setTotal(total);
    };

    const handleWebsite = (e) => {
        setWebsite(parseInt(e));
        const total = monthly + parseInt(e);
        setTotal(total);
    };

    console.log(monthly);

    const handlePdf = () => {
        if (brands?.paymentStatus === "paid") {
            generatePDF(targetRef, {
                filename: `inovice(${brands?.invoiceNo}).pdf`,
                page: {
                    format: "A3",
                    margin: {
                        top: 10,
                        right: 30,
                        bottom: 10,
                        left: 30,
                    },
                },
            });
        } else {
            setShow(false);
            setNotPaid(true);
        }
    };

    useEffect(() => {
        if (show === false && notpaid === true) {
            generatePDF(targetRef, {
                filename: "notPaid.pdf",
                page: {
                    format: "A3",
                    margin: {
                        top: 10,
                        right: 30,
                        bottom: 10,
                        left: 30,
                    },
                },
            });
            setShow(true);
            setNotPaid(false);
        }
    }, [show]);

    const [showDialog, setShowDialog] = useState(false);

    const onHideTyreBrandsDialog = () => {
        setShowDialog(false);
    };

    const onsuccess = () => {
        onHideTyreBrandsDialog();
        toast.success("Payment Made.");
        window.location.reload();
    };

    const handlePayment = () => {
        localStorage.setItem("invoiceId", brands?._id);
        localStorage.setItem("PaymentUser", "user");
        history.push(`/payment`);
    };

    const handleLoyalityDiscount = () => {
        if (loyalty !== 0 && brands?.invoiceType !== "Monthly") {
            if (brands?.customerType !== "partner") {
                if (brands?.customerType !== "admin") {
                    return (
                        <tr className="inside_row">
                            <td>Loyalty Discount</td>
                            <td></td>
                            <td></td>
                            <td>{loyalty}%</td>
                        </tr>
                    );
                } else {
                    return null;
                }
            }
        }
    };

    return (
        <>
            <Dialog visible={showDialog} header="Pay Invoice" style={{ width: "650px" }} onHide={() => setShowDialog(false)}>
                <PaymentModel onHideTyreBrandsDialog={onHideTyreBrandsDialog} onsuccess={onsuccess} />
            </Dialog>
            <div className="Headerit">
                <Tooltip
                    // options
                    title="Download Invoice as PDF"
                    position="bottom"
                    trigger="mouseenter"
                    arrow={true}
                >
                    <Button icon="pi pi-file-pdf" iconPos="right" className="Btn__DarkAdd" onClick={handlePdf} />
                </Tooltip>
                {!paid && (
                    <Tooltip
                        // options
                        title="Pay Invoice"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-wallet" onClick={() => handlePayment()}></Button>
                    </Tooltip>
                )}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
                <div ref={targetRef} style={{ position: "relative", width: `${window?.innerWidth < 768 ? "100%" : "900px"}` }}>
                    {paid && <Water />}
                    <div style={{ width: "100%", marginTop: "20px", padding: `${window?.innerWidth < 768 ? "20px 0px" : "20px 50px"}` }}>
                        <div className="invoice_top">
                            <img src={"https://res.cloudinary.com/dwxqg9so3/image/upload/v1692496183/WhatsApp_Image_2023-08-18_at_6.45.10_AM_qg5n56.jpg"} style={{ height: "180px", width: "220px", objectFit: "cover" }} alt="logo" />
                            <div style={{ width: "100%", marginTop: "25px", display: "flex", padding: "5px", columnGap: `${window?.innerWidth < 768 ? "50px" : "130px"}`, flexWrap: "wrap" }}>
                                <div className="billing_section_1">
                                    <p style={{ fontSize: `${window?.innerWidth < 768 ? "16px" : "18px"}`, fontWeight: "600", color: "black" }}>Client Info</p>
                                    <div style={{ marginTop: "5px" }}>
                                        <span style={{ fontSize: `${window?.innerWidth < 768 ? "12px" : "14px"}`, fontWeight: "500", color: "black" }}>{brands?.clientName}</span>
                                    </div>
                                    <div style={{ marginTop: "5px" }}>
                                        <span style={{ fontSize: `${window?.innerWidth < 768 ? "12px" : "14px"}`, fontWeight: "500", color: "black", paddingTop: "10px", width: `${window?.innerWidth < 768 ? "40px" : "130px"}` }}>{brands?.email}</span>
                                    </div>
                                    <div style={{ marginTop: "5px" }}>
                                        <span style={{ fontSize: `${window?.innerWidth < 768 ? "12px" : "14px"}`, fontWeight: "500", color: "black", paddingTop: "10px" }}>{brands?.phone}</span>
                                    </div>
                                    <br />
                                </div>
                                <div className="billing_section_1">
                                    <p style={{ fontSize: `${window?.innerWidth < 768 ? "16px" : "18px"}`, fontWeight: "600", color: "black" }}>Shipping Address</p>
                                    <div style={{ width: "205px" }}>
                                        <p style={{ fontSize: `${window?.innerWidth < 768 ? "12px" : "14px"}`, fontWeight: "500", color: "black" }}>1150 Lee Wagener Blvd</p>
                                        <p style={{ fontSize: `${window?.innerWidth < 768 ? "12px" : "14px"}`, fontWeight: "500", color: "black", marginTop: "-15px" }}>Ste 203 ZAZIO</p>
                                        <p style={{ fontSize: `${window?.innerWidth < 768 ? "12px" : "14px"}`, fontWeight: "500", color: "black", marginTop: "-15px" }}>Fort Lauderdale</p>
                                        <p style={{ fontSize: `${window?.innerWidth < 768 ? "12px" : "14px"}`, fontWeight: "500", color: "black", marginTop: "-15px" }}>FL 33315</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p style={{ fontSize: `${window?.innerWidth < 768 ? "20px" : "24px"}`, fontWeight: "bold", color: "black", fontFamily: "Arial", marginTop: "50px" }}>{paid ? "Receipt" : "Invoice"}</p>
                        <div style={{ width: "100%", borderTop: "4px solid black", borderBottom: "4px solid black" }}>
                            <div style={{ height: "40px", borderBottom: "4px solid rgb(196, 194, 194)", display: "flex", alignItems: "center", columnGap: `${window?.innerWidth < 768 ? "70px" : "100px"}` }}>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500", paddingTop: "14px", width: "140px" }}>Order Date</p>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500" }}>{brands?.date}</p>
                            </div>
                            <div style={{ height: "40px", borderBottom: "4px solid rgb(196, 194, 194)", display: "flex", alignItems: "center", columnGap: `${window?.innerWidth < 768 ? "70px" : "100px"}` }}>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500", paddingTop: "14px", width: "140px" }}>Payment Method</p>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500" }}>Card {brands?.datePaid ? `at ${brands?.datePaid}` : null}</p>
                            </div>
                            <div style={{ height: "40px", borderBottom: "4px solid rgb(196, 194, 194)", display: "flex", alignItems: "center", columnGap: `${window?.innerWidth < 768 ? "70px" : "100px"}` }}>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500", paddingTop: "14px", width: "140px" }}>Total {brands?.type==="sea"?"Size":"Weight"}</p>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500" }}>{brands?.weight} {brands?.type==="sea"?"Cu ft":"Lbs"}</p>
                            </div>
                            <div style={{ height: "40px", borderBottom: "4px solid rgb(196, 194, 194)", display: "flex", alignItems: "center", columnGap: `${window?.innerWidth < 768 ? "70px" : "100px"}` }}>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500", paddingTop: "14px", width: "140px" }}>Contents</p>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500" }}>{brands?.descriptions}</p>
                            </div>

                            <div style={{ marginTop: "60px" }}>
                                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                    <thead style={{ textAlign: "left", color: "black" }}>
                                        <tr>
                                            <th>Product</th>
                                            <th>{brands?.type==="sea"?"Size":"Weight"}</th>
                                            <th>Price</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody className="inside_table_body">
                                        {brands?.invoiceType !== "Monthly" &&
                                            packages?.map((item, index) => {
                                                let temp;
                                                let prev = packages[index - 1]?.product;
                                                if (brands?.customerType === "client") {
                                                    if (brands?.size === "sea") {
                                                        temp = "Total Size of Cargo";
                                                    } else {
                                                        if (item?.product === prev) {
                                                            temp = "Additional Pounds";
                                                        } else {
                                                            temp = "1st Pound of Cargo";
                                                        }
                                                    }
                                                } else {
                                                    if (brands?.type === "sea") {
                                                        temp = "Total Size of Cargo";
                                                    } else {
                                                        temp = "Total Pounds of Cargo";
                                                    }
                                                }
                                                return (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>{temp}</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>{item?.weight} {item?.type==="sea"?"cu":"lb"}</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${item?.price}</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${(item?.total).toFixed(2)}</td>
                                                    </tr>
                                                );
                                            })}

                                        {/* {!show && brands?.invoiceType!=="Monthly"  && price === 0 && oversize==0 && packaging==0 && pickup===0 && delivery===0 && declaration===0 && custom===0 && loyalty===0 && dangerous===0 ? null : (
                                            <tr style={{}}>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "600" }}>Add-ons</td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                            </tr>
                                        )} */}

                                        <>
                                            <>
                                                {/* cost of goods */}
                                                {brands?.invoiceType !== "Monthly" && brands?.itemsPrice !== 0 && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Cost of Goods</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${brands?.itemsPrice}</td>
                                                    </tr>
                                                )}

                                                {/* oversize package  */}
                                                {brands?.invoiceType !== "Monthly" && brands?.oversizeFee !== 0 && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>{brands?.type==="sea"?"Handling":"Oversize Fee"}</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${brands?.oversizeFee}</td>
                                                    </tr>
                                                )}

                                                {/* packaging Fee */}
                                                {brands?.invoiceType !== "Monthly" && brands?.packagingFee !== 0 && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Packaging</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${brands?.packagingFee}</td>
                                                    </tr>
                                                )}

                                                {/* pickup fee */}
                                                {brands?.invoiceType !== "Monthly" && brands?.pickupFee !== 0 && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Pickup</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${brands?.pickupFee}</td>
                                                    </tr>
                                                )}

                                                {/* delivery fee */}
                                                {brands?.invoiceType !== "Monthly" && brands?.deliveryFee !== 0 && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Delivery</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${brands?.deliveryFee}</td>
                                                    </tr>
                                                )}

                                                {/* declaration fee */}
                                                {!show && brands?.invoiceType !== "Monthly" && brands?.declarationFee !== 0 && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>{brands?.type==="sea"?"Pre-Clearance":"Declaration Fee"}</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${brands?.declarationFee}</td>
                                                    </tr>
                                                )}

                                                {/* custom duties */}
                                                {brands?.invoiceType !== "Monthly" && brands?.customDuties !== 0 && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Custom Duties</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${brands?.customDuties}</td>
                                                    </tr>
                                                )}

                                                {/* dangerous item fee */}
                                                {brands?.dangerousItemFee !== 0 && brands?.invoiceType !== "Monthly" && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Dangerous Item Fee</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>$ {brands?.dangerousItemFee}</td>
                                                    </tr>
                                                )}

                                                {/* loyality discount */}
                                                {/* {handleLoyalityDiscount()} */}

                                                {/* courier monthly */}
                                                {brands?.invoiceType === "Monthly" && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Monthly Fee</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${brands?.MonthlyFee}</td>
                                                    </tr>
                                                )}

                                                {/* courier website usage */}
                                                {!show && brands?.invoiceType === "Monthly" && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Website usage Fee</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${brands?.WebsiteFee}</td>
                                                    </tr>
                                                )}
                                            </>
                                        </>
                                        {/* {!show && brands?.invoiceType!=="Monthly"  && price === 0 && oversize==0 && packaging==0 && pickup===0 && delivery===0 && declaration===0 && custom===0 && loyalty===0 && dangerous===0 ? null :  <tr style={{ borderBottom: "4px solid black" }}>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "600" }}></td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                        </tr>} */}

                                        {/* subTotal */}
                                        <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Sub-Total</td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${brands?.customerType === "partner" || brands?.customerType === "admin" ? (SubTotal).toFixed(2) : (total).toFixed(2)}</td>
                                        </tr>
                                        {brands?.customerType === "partner" ||
                                            (brands?.customerType === "admin" && (
                                                <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>{brands?.customerType === "partner" ? "Credit Discount" : "Admin Discount"}</td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>- ${brands?.discountedAmount}</td>
                                                </tr>
                                            ))}

                                        {/* Tax */}
                                        <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Tax</td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>$0</td>
                                        </tr>

                                        {/* Total */}
                                        {brands?.paymentStatus === "pending" ? (
                                            <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Total</td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${(brands?.invoiceAmount).toFixed(2)}</td>
                                            </tr>
                                        ) : null}
                                        {brands?.paymentStatus === "partially paid" ? (
                                            <>
                                                <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Paid Amount</td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${(brands?.paidAmount).toFixed(2)}</td>
                                                </tr>

                                                <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Remaining Amount</td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${(brands?.invoiceAmount).toFixed(2)}</td>
                                                </tr>
                                            </>
                                        ) : null}
                                        {brands?.paymentStatus === "paid" ? (
                                            <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Total</td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${(brands?.paidAmount).toFixed(2)}</td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <p style={{ fontSize: "18px", marginTop: "50px", fontFamily: "Arial", color: "black", fontWeight: "bold" }}>Customer Note</p>
                            <p style={{ color: "black", marginTop: "-10px" }}>THANK YOU for choosing ZAZIO as your shipping partner! Please see your order updates below.</p>
                            <ul>
                                <li style={{ fontSize: "16px", color: "black", lineHeight: "24px" }}>Status: Preparing Package for Shipment</li>
                                <li style={{ fontSize: "16px", color: "black", lineHeight: "24px" }}>Shipment: {brands?.shipmentNumber}</li>
                                <li style={{ fontSize: "16px", color: "black", lineHeight: "24px" }}>Package: {brands?.Waybills}</li>
                                {!show && notes && <li style={{ fontSize: "16px", color: "black", lineHeight: "24px" }}>Notes: {notes}</li>}
                                {show && (
                                    <div style={{ display: "flex", columnGap: "10px", marginTop: "10px" }}>
                                        <p>Notes:</p>
                                        <textarea value={notes} onChange={(e) => setNotes(e.target.value)} style={{ width: "70%", height: "100px", borderRadius: "6px", border: "1px solid rgb(196, 194, 194)", padding: "10px", fontSize: "16px", color: "black", fontWeight: "500" }}></textarea>
                                    </div>
                                )}
                            </ul>
                            <p style={{ color: "black" }}> PLEASE USE 00000 for the ZIP Code when using a Jamaican Debit/Credit Card) to make payment.</p>
                            {/* <a href="https://portal.zazio.co">Your Payment Link: https://portal.zazio.co</a> */}
                            <img
                                src="https://res.cloudinary.com/dwxqg9so3/image/upload/v1696375833/WhatsApp_Image_2023-09-25_at_03.12.56_463150e6_ua2zka.jpg"
                                style={{
                                    width: "200px",
                                    height: "auto",
                                    objectFit: "contain",
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePayment()}
                            ></img>
                            <p style={{ color: "black", marginTop: "50px", fontSize: "12px" }}>
                                <span style={{ fontWeight: "bold" }}>ZAZIO INC</span> is a registered corporation in Florida, United States
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Invoice;
