import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { handleGetRequest } from "../../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../../services/PostTemplate";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { useLocation } from "react-router-dom";
import SofStop from "../../../modals/SofStop";
import { Tooltip } from "react-tippy";
import ClipLoader from "react-spinners/ClipLoader";
import "../../customers/customers.scss";
import AddMilageDialog from "./AddMilageDialog";

function Milages() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [selectedRow, setselectedRow] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [brands, setBrands] = useState([]);
    const [skip, setSkip] = useState(0);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const getBrands = async () => {
        setLoading(true);
        const res = await handleGetRequest(`/getMilages`);
        setBrands(res?.data);
        setLoading(false);
    };
    useMemo(() => {
        getBrands();
    }, [skip]);

    const [data, setData] = useState();

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-pencil" className="p-button-rounded mr-2 Elipse_Icon" onClick={(e) => handleActionButton(e, rowData)} aria-controls="popup_menu" aria-haspopup />
            </div>
        );
    };
    const handleActionButton = (e, rowdata) => {
        setShowDialog(true);
        setData(rowdata);
    };

    const onHideTyreBrandsDialog = () => {
        setShowDialog(false);
    };
    const handledDelete = async () => {
        setShowSoftStop(true);
    };

    const onsuccess = () => {
        onHideTyreBrandsDialog();
        toast.success("Milage report added");
        window.location.reload();
    };

    const [showSoftStop, setShowSoftStop] = useState(false);

    const onHideSoftStop = () => {
        setShowSoftStop(false);
    };

    const onSuccessSoftStop = async () => {
        setShowSoftStop(false);
        if (selectedRow?.length > 0) {
            const selectedIds = selectedRow.map((val) => {
                return val?._id;
            });
            const data = {
                ids: selectedIds,
            };
            const res = await dispatch(handlePostRequest(data, "/deleteMilage", true, true));
            if (res?.success) {
                getBrands();
                toast.success("milage report deleted");
                window.location.reload();
            }
        }
    };

    const imageTemplate = (rowData) => {
        return <div style={{
            display:"flex",
            flexDirection:"row",
            flexWrap:"wrap",
        }}>
            {rowData?.images?.map((val, index) => {
                return (
                    <img src={val} style={{ width: "50px", objectFit: "cover", marginTop: "20px", height: "50px" }}></img>
                );
            })}
        </div>;
    };
    return (
        <>
            <Dialog visible={showDialog} header="Milage Report" style={{ width: "650px" }} onHide={() => setShowDialog(false)}>
                <AddMilageDialog onHideTyreBrandsDialog={onHideTyreBrandsDialog} onsuccess={onsuccess} dat={data} />
            </Dialog>
            <Dialog visible={showSoftStop} style={{ width: "650px" }} onHide={() => setShowSoftStop(false)}>
                <SofStop onHideSoftStop={onHideSoftStop} onSuccessSoftStop={onSuccessSoftStop} type="milage report"></SofStop>
            </Dialog>

            <div className="Page__Header">
                <div>
                    <h2>Milage Reports</h2>
                </div>
                <div className="Top__Btn">
                    <Tooltip
                        // options
                        title="Add new milage report"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-plus" iconPos="right" onClick={() => setShowDialog(true)} className="Btn__Blue" />
                    </Tooltip>
                    <Tooltip
                        // options
                        title="Delete selected customers"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-trash" iconPos="right" onClick={handledDelete} className="Btn__DarkDelete" style={{ width: "240px" }} />
                    </Tooltip>
                </div>
            </div>
            <div className="card">
                <DataTable
                    filterDisplay="row"
                    paginator
                    rows={100}
                    rowsPerPageOptions={[100, 500, 1000]}
                    className="datatable-responsive custom-datatable"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                    emptyMessage="No List found."
                    // responsiveLayout="scroll"
                    value={brands}
                    selection={selectedRow}
                    onSelectionChange={(e) => setselectedRow(e.value)}
                    sortMode="multiple"
                    loadingIcon="pi pi-spinner"
                    loading={loading}
                    loadingTemplate={<ClipLoader color={"#2C4870"} loading={loading} size={30} />}
                    loadingText="Loading..."
                    style={{ Height: "100px", overflowY: "auto" }}
                >
                    <Column selectionMode="multiple" style={{ width: "3em" }} />
                    <Column header="Action" body={actionBodyTemplate} />
                    <Column filter field="id" header="Report Id" sortable />
                    <Column filter field="username" header="User" sortable />
                    <Column filter field="vehicleNumber" header="Vehicle" sortable />
                    <Column filter field="startMilage" header="Starting Milage" sortable />
                    <Column filter field="amount" header="Fuel Amount" sortable />
                    <Column filter field="currency" header="Currency" sortable />
                    <Column body={imageTemplate} header="Image" />
                    {/* <Column filter field="role" header="User type" filterElement={() => handleFilter("role")} sortable />
            <Column filter field="phone" header="Phone Number" filterElement={() => handleFilter("phone")} sortable />
            <Column filter header="Created On" field="date" filterElement={() => handleFilter("date")} /> */}
                </DataTable>
            </div>
        </>
    );
}

export default Milages;
