import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../../services/PostTemplate";
import moment from "moment/moment";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { handleGetRequest } from "../../../services/GetTemplate";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase/firebase";
import ClipLoader from "react-spinners/ClipLoader";
import { handlePutRequest } from "../../../services/PutTemplate";

function AddVehiclesDialog({ onHideTyreBrandsDialog, onsuccess, dat }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    console.log(dat);

    useEffect(() => {
        if (dat) {
            setImage(dat?.image);
        }
    }, [dat]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            Number: dat?.Number || "",
            type: dat?.type || "",
            image: dat?.image || "",
            id:"",
        },

        onSubmit: async (data) => {
            data.image = image;
            data.dateJoined = Date.now();
            if (!data?.Number) {
                toast.error("Please enter vehicle number");
            } else if (!data?.type) {
                toast.error("Please enter vehicle type");
            } else {
                if (!dat) {
                    const result = await dispatch(handlePostRequest(data, "/addVehicle", true, true));
                    if (result !== "error") {
                        onsuccess();
                    }
                    setImage(null);
                } else {
                    data.id=dat._id;
                    const result = await handlePutRequest(data, "/updateVehicle");
                    if (result?.success) {
                        onsuccess();
                    }
                }
            }
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const uploadImage = async (file) => {
        setLoading(true);
        let name = file.name;
        let filename = name.replace(/\.[^/.]+$/, "") + "-" + Math.floor(Math.random() * 10000000 + 1);
        const storageRef = ref(storage, filename);
        await uploadBytes(storageRef, file).then((snapshot) => {});
        await getDownloadURL(storageRef).then(async (url) => {
            setImage(url);
        });
        setLoading(false);
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="Number" className={classNames({ "p-error": isFormFieldValid("Number") }, "Label__Text")}>
                                Vehicle Number
                            </label>
                            <InputText id="Number" name="Number" value={formik.values.Number} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("Number") }, "Input__Round")} />

                            {getFormErrorMessage("Number")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="type" className={classNames({ "p-error": isFormFieldValid("type") }, "Label__Text")}>
                                Vehicle Type
                            </label>
                            <InputText id="type" name="type" value={formik.values.type} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("type") }, "Input__Round")} />

                            {getFormErrorMessage("type")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        {loading ? (
                            <ClipLoader color={"#2C4870"} loading={loading} size={30} />
                        ) : (
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ "p-error": isFormFieldValid("email") }, "Label__Text")}>
                                    Vehicle Image
                                </label>
                                <div style={{ marginTop: "10px" }}>
                                    <input type="file" onChange={(e) => uploadImage(e.target.files[0])}></input>
                                </div>

                                {getFormErrorMessage("email")}
                            </div>
                        )}
                    </div>
                    <div>
                        <img
                            src={image}
                            style={{
                                width: "150px",
                                objectFit: "cover",
                                marginTop: "20px",
                                height: "150",
                            }}
                        ></img>
                    </div>
                </div>
                <div className="Down__Btn">
                    <Button label={dat ? "Edit" : "Create"} className="Btn__Dark" />
                </div>
            </form>
        </>
    );
}

export default AddVehiclesDialog;
