import React from "react";
import "./deliveryreport.scss";
import { useHistory } from "react-router-dom";

function DeliveryReport() {
    const history = useHistory();
    return (
        <div className="containers">
            <div className="advance_inner">
                <div className="feature_block" onClick={() => history.push("/vehicles")}>
                    <p>Vehicles</p>
                </div>
                <div className="feature_block" onClick={() => history.push("/milageReports")}>
                    <p>Milage reports</p>
                </div>
            </div>
        </div>
    );
}

export default DeliveryReport;
