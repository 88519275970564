import React, { useState } from "react";
import styles from "./financialReport.module.scss";
import FlightReport from "../../components/FlightReport";
import MonthlyReport from "../../components/MonthlyReport";
import CustomReport from "../../components/CustomReport";

function FinancialReport() {
    const [reportType, setReportType] = useState("Custom Report");
    return (
        <div className={styles.container}>
            <div className={styles.buttonDiv}>
                <button onClick={()=>setReportType("Flight Report")}>Flight Report</button>
                <button onClick={()=>setReportType("Monthly Report")}>Monthly Report</button>
                <button onClick={()=>setReportType("Custom Report")}>Custom Report</button>
            </div>
            <p className={styles.title}>{reportType}</p>
            {reportType === "Flight Report" && <FlightReport/>}
            {reportType === "Monthly Report" && <MonthlyReport/>}
            {reportType === "Custom Report" && <CustomReport/>}
        </div>
    );
}

export default FinancialReport;
